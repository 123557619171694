<template>
    <div class="zt-page-content" ref="load">
        <el-form v-if="showImgs" :model="ruleForm" :rules="rules" ref="formCheck1" label-width="120px">
            <div class="zt-block">
                <div class="zt-block-head">基本信息</div>
                <div class="zt-block-content">
                    <el-form-item label="商品名称" prop="name">
                        <el-input v-model="ruleForm.name" maxlength="30" placeholder="填写商品名称"></el-input>
                        <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                            >{{(ruleForm.name || '').length}}/30</span>
                    </el-form-item>
                    <el-form-item label="商品简称">
                        <el-input v-model="ruleForm.sname" maxlength="30" placeholder="填写商品简称"></el-input>
                        <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                            >{{(ruleForm.sname || '').length}}/30</span>
                    </el-form-item>
                    <el-form-item label="单品风格" prop="style_no">
                        <el-select v-model="ruleForm.style_no" placeholder="请选择风格">
                            <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品描述">
                        <el-input v-model="ruleForm.intro" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="单品类目" prop="cat_id">
                        <el-cascader :options="cats" :disabled="product_no&&catId?true:false" @change="handleCat" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                            v-model="ruleForm.cat_id" placeholder="请选择类目">
                        </el-cascader>
                    </el-form-item>
                    <div v-if="ruleForm.attr && ruleForm.attr.length>0 && ruleForm.attr[0].id">
                        <el-form-item class="block-bg" :style="(idx+1)==ruleForm.attr.length ? 'margin-bottom:0;' : ''"
                            v-for="(attr,idx) in ruleForm.attr" :key=idx 
                            :prop="attr.nullable ? ('attr.' + idx + '.values') : ''"
                            :rules="attr.nullable ? rules.attr : []"
                            :label="attr.name">
                            <div v-if="attr.type=='single' && attr.items && attr.items.length>0">
                                <el-select v-model="attr.values" placeholder="请选择">
                                    <el-option v-for="item in attr.items" :key="item.id" :label="item.value" :value="item.id"></el-option>
                                </el-select>
                            </div>
                            <div v-if="attr.type=='mutil' && attr.items && attr.items.length>0">
                                <el-checkbox-group v-model="attr.values">
                                    <el-checkbox v-for="item in attr.items" :key="item.id" :label="item.id" :value="item.id">{{item.value}}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="zt-block">
                <div class="zt-block-head">设计信息</div>
                <div class="zt-block-content">
                    <el-form-item label="设计尺寸">
                        <DesignSize v-model="ruleForm.dimension"></DesignSize>
                    </el-form-item>
                    <el-form-item style="padding-top:10px;" label="设计理念">
                        <div style="position:relative;max-width:500px;">
                            <el-input type="textarea" v-model="ruleForm.design_idea" maxlength="120" rows="5" style="max-width:500px;"></el-input>
                            <div class="font14-grey1" style="position:absolute;right:10px;bottom:6px;">{{(ruleForm.design_idea || '').length}}/120</div>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <div class="zt-block">
                <div class="zt-block-head">图片与视频</div>
                <div class="zt-block-content">
                    <el-form-item label="3D 单品渲染图" prop="photo_render">
                        <div>
                            <UploadImgs v-if="showImgs" :limit="5" v-model="ruleForm.photo_render" prefix='product/render'></UploadImgs>
                        </div>
                    </el-form-item>
                    <el-form-item label="标高图">
                        <div>
                            <UploadImgss v-if="showImgs" v-model="ruleForm.photo_size" :limit="10" prefix='product/size'></UploadImgss>
                        </div>
                    </el-form-item>
                    <el-form-item label="素材图" style="margin-bottom:0px;">
                        <div>
                            <UploadImgss v-if="showImgs" v-model="ruleForm.photo_story" :limit="10" prefix='product/story'></UploadImgss>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <div class="zt-block">
                <div class="zt-block-head">文件</div>
                <div class="zt-block-content">
                    <el-form-item label="设计CAD文件：" style="margin-bottom:0;">
                        <div style="position:relative;">
                            <UploadCads v-if="showImgs" v-model="ruleForm.photo_cad" prefix='product/cad'></UploadCads>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <div class="zt-block">
                <div class="zt-block-head" v-if="ruleForm.accessory && ruleForm.accessory.length>0">附件信息</div>
                <div class="zt-block-content" v-if="ruleForm.accessory && ruleForm.accessory.length>0" style="padding:0 30px;">
                    <div class="list-item" v-for="(item,idx) in ruleForm.accessory" :key="idx">
                        <div style="position:absolute;top:30px;right:0;z-index:999;" class="flex flex-align-center">
                            <div style="font-size:16px;color:#292929;line-height:42px;margin-right:10px">附件{{idx+1}} </div>
                            <div v-if="item.id">
                                <el-button v-if="item.stat==3" @click="changeSwitch(item,idx)" size="mini" type="text" plain>启用</el-button>
                                <el-button v-if="item.stat==2" @click="changeSwitch(item,idx)" size="mini" type="text" plain>禁用</el-button>
                            </div>
                            <el-button v-else @click="delAccessory(idx)" style="font-size:14px;color:#FF4D4F;" type="text">
                                <i class="iconfont icon-icon7"></i> 删除
                            </el-button>
                        </div>
                        <el-form-item label="附件名称" :prop="'accessory.'+ idx +'.name'" :rules="rules.accessoryName">
                            <el-input v-model="item.name" placeholder="请填写附件名称"></el-input>
                        </el-form-item>
                        <el-form-item label="设计尺寸">
                            <DesignSize v-model="item.dimension"></DesignSize>
                        </el-form-item>
                        <el-form-item label="标高图">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="item.photo_size" :limit="10" prefix='product/size'></UploadImgss>
                            </div>
                        </el-form-item>
                        <el-form-item label="素材图" style="margin-bottom:0px;">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="item.photo_story" :limit="10" prefix='product/story'></UploadImgss>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="add-accessory flex flex-align-center flex-pack-center">
                    <div @click="addAccessory()" class="addSeries">
                        <span>+</span>
                        <span>点击添加附件</span>
                    </div>
                </div>
            </div>
        </el-form>
        <!-- <div class="zt-block" style="padding:20px 0">
            <div style="max-width:400px;margin:0 auto;">
                <div class="flex flex-justify-around">
                    <el-button size="small" type="primary" @click="back()">取消</el-button>
                    <el-button size="small" @click="saveDraft()" type="primary" plain>存草稿</el-button>
                    <el-button size="small" @click="submitShow()" type="primary">下一步</el-button>
                </div>
            </div>
        </div> -->

    </div>
</template>

<script>
    import _ from 'lodash';
    import { getCats, getAttrs, getStyles } from '@/service/item';
    import { singleDetail, addOrUpdateProduct } from '@/service/design';
    import { fetchTree } from '@/util';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    import UploadImgss from '@/components/upload/UploadImgss.vue';
    import UploadCads from '@/components/upload/UploadCads.vue';
    import DesignSize from '@/components/factory/DesignSize.vue';

    export default {
        components:{
            UploadImgs,UploadImgss,UploadCads,
            DesignSize
        },
        // model: {
        //     prop: "form",
        //     event: "change"
        // },
        props:['form','setting'],
        data() {
            return {
                ruleForm: {
                    id: "",
                    name: "",
                    sname: "",
                    // module_no:null,
                    style_no: "",
                    cat_id: "",
                    attr: [],
                    intro:"",
                    design_idea:"",
                    dimension: [],
                    photo_render:[],
                    photo_cad:[],
                    photo_size:[],
                    photo_story:[],
                    accessory:[],
                },
                rules: {
                    name: [{ required: true, message: '填写商品名称', trigger: 'blur' }],
                    module_no: [{ required: true, message: '请填写商品型号', trigger: 'blur' }],
                    style_no: [{ required: true, message: '请选择风格', trigger: 'blur' }],
                    cat_id: [{ required: true, message: '请选择类目', trigger: 'blur' }],
                    attr: [{
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else if (value && JSON.stringify(value) != '[]') {
                                callback();
                            } else {
                                let _idx = rule.field.split(".")[1];
                                let _name = this.ruleForm.attr[_idx].name;
                                if (!value) {
                                    callback(new Error(`请选择${_name}`));
                                } else {
                                    callback(new Error(`${_name} 至少选择一项!`));
                                }
                            }
                        },
                        trigger: "blur"
                    }],
                    design_idea: [{ required: true, message: '请填写设计理念', trigger: 'blur' }],
                    photo_render: [{ required: true, message: '请上传3D 单品渲染图', trigger: 'blur' }],
                    price: [{ required: true, message: '请填写价格', trigger: 'blur' }],
                    value: [{ required: true, message: '请填写内容', trigger: 'blur' }],
                    accessoryName: [{ required: true, message: '请填写附件名称', trigger: 'blur' }],
                    accessoryModuleNo: [{ required: true, message: '请填写型号', trigger: 'blur' }],
                },
                autoSave: null,
                tpls: [],
                cats: [],
                catList: [],
                styles: [],
                product_no:null,
                catId:null,
                showImgs:false,
                previewFlag:false,
                previewData:null,
            }
        },
        watch:{
            form:{ // 从单品编辑进来
                handler() {
                    if (this.form && this.form.id) {
                        this.ruleForm.id = this.form.id;
                        this.refreshItem();
                    } else {
                        this.showImgs = true;
                    }
                    console.log("form",this.ruleForm);
                },
                deep: true
            },
            setting:{ // 从套系编辑-套系下-单品进来
                handler() { 
                    if (this.setting && this.setting.id) {
                        this.ruleForm.id = this.setting.id;
                        // this.ruleForm.set_no = this.setting.set_no;
                        this.refreshItem();
                    } else {
                        this.showImgs = true;
                    }
                    console.log("setting",this.setting);
                },
                deep: true
            }
        },
        async created() {
            console.log("form",this.form,this.setting);
            // 从单品编辑进来
            if (this.form && this.form.id) {
                this.ruleForm.id = this.form.id;
                this.refreshItem();
            } else {
                this.showImgs = true;
            }
            // 从套系编辑-套系下-单品进来
            if (this.setting && this.setting.id) {
                this.ruleForm.id = this.setting.id;
                this.refreshItem();
            }
            console.log(this.ruleForm);
            this.catList = await getCats();
            this.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            this.styles = await getStyles();
        },
        mounted() {},
        methods: {
            // 选择类目
            handleCat(e) {
                console.log(e);
                this.get_attrs(e);
            },
            // 获取类目下面的spu属性
            get_attrs(catId,attr) {
                let list = attr;
                getAttrs(catId,{hasValue:true}).then(rst => {
                    if (rst && rst.length>0) {
                        rst.forEach(e => {
                            this.$set(e,'values',[]);
                            if (this.ruleForm.attr && this.ruleForm.attr.length>0) {
                                this.ruleForm.attr.forEach((n)=>{
                                    if (e.id == n.name) {
                                        if (e.type=='mutil') {
                                            e.values = n.values;
                                        } else {
                                            e.values = n.values[0];
                                        }
                                    }
                                })
                            }
                        });
                    }
                    this.ruleForm.attr = rst;
                    console.log(this.ruleForm.attr);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            back() {
                this.$router.go(-1);
            },
            refreshItem() {
                this.showImgs = false;
                singleDetail(this.ruleForm.id).then(rst => {
                    console.log("rst",rst);
                    this.catId = rst.cat.id;
                    if (rst) {
                        this.ruleForm = {
                            id: rst.id,
                            name: rst.name,
                            sname: rst.sname,
                            // module_no: rst.module_no,
                            intro: rst.intro,
                            set_no: rst.set_no,
                            style_no: rst.style.id,
                            cat_id: rst.cat.id,
                            attr: rst.attr,
                            design_idea: rst.design_idea,
                            dimension: rst.dimension,
                            photo_render: rst.photo_render,
                            photo_cad: rst.photo_cad,
                            photo_size: rst.photo_size,
                            photo_story: rst.photo_story,
                            accessory: rst.accessory
                        };
                    }
                    this.get_attrs(this.catId,rst.attr);
                    console.log("ruleForm",this.ruleForm);
                    this.showImgs = true;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            // 添加款式
            addAccessory() {
                if (!this.ruleForm.accessory) {
                    this.ruleForm.accessory = [];
                }
                var obj = { name:null,module_no:'',dimension: [], photo_size:[],photo_story:[] };
                this.ruleForm.accessory.push(obj);
                console.log(this.ruleForm.accessory);
            },
            delAccessory(index) {
                this.$confirm('确认是否删除附件?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.$message({ type: 'success', message: '删除成功!' });
                    this.ruleForm.accessory.splice(index,1);
                });
            },
            checkRuleForm() {
                let attr = [];
                if (this.ruleForm.attr && this.ruleForm.attr.length>0) {
                    this.ruleForm.attr.forEach((e)=>{
                        if (e.type == "single") {
                            attr.push({
                                name: e.id,
                                values: [e.values]
                            })
                        } else if (e.type == "mutil") {
                            attr.push({
                                name: e.id,
                                values: e.values
                            })
                        }
                    })
                }
                return attr;
            },
            // 保存
            submitForm1() { 
                let _item = _.clone(this.ruleForm);
                _item.attr = this.checkRuleForm();
                if (!_item.sname) {
                    _item.sname = _item.name;
                }
                this.$refs['formCheck1'].validate((valid) => {
                    if (valid) {
                        if (this.setting && this.setting.set_no) { // 用于分类下创建商品
                            console.log("save")
                            _item.set_no = this.setting.set_no;
                            this.save(_item);
                        } else { // 用于创建单品
                            this.submit(_item);
                        }
                    }
                });
            },
            submit(_item) {
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                addOrUpdateProduct(_item).then(rst => {
                    _item.id = rst.id;
                    this.$emit("step",_item,2);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
            save(_item) {
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                addOrUpdateProduct(_item).then(rst => {
                    let index = this.catList.findIndex(e => e.id == _item.cat_id);
                    this.$emit("saveProduct",{
                        id: rst.id,
                        setNo: _item.set_no,
                        name: _item.name,
                        photo_render: _item.photo_render,
                        cat: this.catList[index],
                    });
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            }
        }
    }
</script>
<style>

</style>
<style scoped lang="less">
    .list-item {
        position:relative;
        padding:30px 0;
        border-bottom:1px solid #E6E6E6;
    }
    .list-item:last-child {
        border-bottom:0;
    }
    .add-accessory {
        height: 70px;
        border-top: 1px solid #E6E6E6;
        >span {
            font-size: 16px;
        }
        .addSeries {
            text-align: center;
            // padding: 10px 90px 0;
            cursor: pointer;
            >span {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: #016FFF;
                &:first-child {
                    width: 18px;
                    height: 18px;
                    line-height: 15px;
                    text-align: center;
                    font-size: 18px;
                    color: #016FFF;
                    border: 1px solid #016FFF;
                    border-radius: 50%;
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
    }
    .el-input, .el-cascader, .el-select {
        width: 240px;
    }
</style>
<style scoped>
    
</style>